import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot } from '@angular/router';
import { AccountService } from "@services/account/account.service";

export const userAuthGuard: CanActivateChildFn =
    (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
        const router = inject(Router);
        let isAuthenticated = AccountService.isAuthenticated();
        if (isAuthenticated)
            return true;

        let queryString = parseQueryString(_state.url);
        let url = _state.url;
        if (url.contains("?"))
            url = url.substring(0, url.indexOf("?"));

        queryString["returnUrl"] = url;
        return router.navigate([ 'open-area', 'sign-in' ], { queryParams: queryString });
    };


export const patientPortalAuthGuard: CanActivateChildFn =
    (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
        const router = inject(Router);
        let isAuthenticated = AccountService.isInRole('portal.all');
        if (isAuthenticated)
            return true;

        return router.navigate([ 'secure-area', 'welcome' ],
            { queryParams: { returnUrl: _state.url } });
    };

export const paymentPortalAuthGuard: CanActivateChildFn =
    (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
        const router = inject(Router);
        let isAuthenticated = AccountService.isInRole('payment.all') && AccountService.isMerchantAvailable();
        if (isAuthenticated)
            return true;

        return router.navigate([ 'secure-area', 'welcome' ],
            { queryParams: { returnUrl: _state.url } });
    };

export function parseQueryString(relativeUrl: string): Record<string, string> {
    // Check if the URL contains a query string
    const queryStringIndex = relativeUrl.indexOf('?');
    if (queryStringIndex === -1) {
        return {}; // No query string present
    }

    // Extract the query string part
    const queryString = relativeUrl.substring(queryStringIndex + 1);

    // Use URLSearchParams to parse the query string
    const searchParams = new URLSearchParams(queryString);

    // Create an empty object to store the query parameters
    const queryParams: Record<string, string> = {};

    // Iterate over the search params and add them to the queryParams object
    searchParams.forEach((value, key) => {
        queryParams[key] = value;
    });

    return queryParams;
}
